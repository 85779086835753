import { useDispatch, useSelector } from 'react-redux';
import { setDashboardAction, setOldConfigAction } from './dashboard.state';

export const useDashboardLayout = () => {
  const dashboard = useSelector((state) => state.dashboard.dashboard);
  const dispatch = useDispatch();

  const setDashboard = (newData) => {
    dispatch(setDashboardAction(newData));
  };

  return { ...dashboard, setDashboard };
};

export const useOldConfig = () => {
  const old_config = useSelector((state) => state.dashboard.old_config);
  const dispatch = useDispatch();

  const setOldConfig = (newConfig) => {
    dispatch(setOldConfigAction(newConfig));
  };

  return { old_config, setOldConfig };
};
