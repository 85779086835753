import React from 'react';
import DateRangePicker from 'components/form/fields/DateRangePicker';
import { useHistory } from 'react-router-dom';
import P from 'router/paths';
import AdsetNameSelect from 'components/Selects/AdsetNameSelect';
import { Button, Tooltip } from 'antd';
import Icon from '@mdi/react';
import { mdiHomeEdit, mdiContentSave, mdiClose } from '@mdi/js';
import { useDashboardLayout, useOldConfig } from 'store/redux/states/dashboard/dashboard.hooks';
import useQueryParams from 'hooks/useQueryParams';
import { useUpdateDashboardMutation } from 'store/redux/states/dashboard/dashboard.api';

const DashboardMainMenu = () => {
  const { setUrlParams, searchParams, removeParams } = useQueryParams();
  const { edit_layout } = searchParams;
  const history = useHistory();
  const [updateDashboard] = useUpdateDashboardMutation();
  const { config } = useDashboardLayout();
  const { old_config } = useOldConfig();

  const updateLayout = () => {
    updateDashboard({ id: 18, config: config, name: 'dashboard' }).unwrap();
    removeParams(['edit_layout']);
  };

  const cancelUpdating = () => {
    console.log(old_config, config);
    updateDashboard({ id: 18, config: old_config, name: 'dashboard' }).unwrap();
    removeParams(['edit_layout']);
  };

  return (
    <div className='sm:flex grid items-center justify-between px-4 py-2 pr-8 gap-4'>
      <div className='flex items-center gap-4 h-full'>
        <DateRangePicker changeGlobal={true} date_key='global' />
        <AdsetNameSelect
          style={{ minWidth: 300, height: 38 }}
          placeholder='Go To Ad'
          onChange={(val) => {
            val.map((id) => {
              return history.push(P.REPORTING.INNER.DASHBOARD.replace(':id', id));
            });
          }}
        />
      </div>
      <div className='flex items-center gap-2'>
        {edit_layout ? (
          <div className='flex items-center gap-2'>
            <Button
              size='medium'
              icon={<Icon path={mdiContentSave} size={0.8} />}
              onClick={updateLayout}
              type='primary'
            >
              Save
            </Button>
            <Tooltip title='Close' destroyTooltipOnHide mouseEnterDelay={0.1}>
              <Button
                color='primary'
                shape='circle'
                variant='dashed'
                icon={<Icon path={mdiClose} size={0.8} />}
                onClick={cancelUpdating}
              />
            </Tooltip>
          </div>
        ) : (
          <Tooltip title='Drag & Resize' destroyTooltipOnHide mouseEnterDelay={0.1}>
            <Button
              shape='circle'
              type='primary'
              size='medium'
              icon={<Icon path={mdiHomeEdit} size={0.8} />}
              onClick={() => setUrlParams({ edit_layout: true })}
            />
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export default DashboardMainMenu;
