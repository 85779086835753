import { useFormik } from 'formik';
import React, { useState } from 'react';
import { useEffect } from 'react';
import ninjaApi from 'store/redux/apis';
import { Button, Spin, Form, Input, Select } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import ConversionsSelector from 'components/Selects/ConversionsSelector';

const ConversionRulesForm = (props) => {
  const { editing = false, id = null, onSubmit, loading } = props;
  const [sale, setSale] = useState([]);
  const [create, setCreate] = useState([]);
  const [sourceOptions, setSourceOptions] = useState([]);
  const [getConversionRuleQuery, { isLoading }] = ninjaApi.useLazyGetConversionRuleQuery();
  const [getFormsQuery] = ninjaApi.useLazyGetFormsQuery();
  const [getPostbacksQuery] = ninjaApi.useLazyListPostbacksQuery();

  const formSubmitted = () => {
    const data = form.values;
    data.id = id;

    onSubmit && onSubmit(data);
  };

  const form = useFormik({
    initialValues: {
      name: '',
      source_type: null,
      source_ids: [],
      on_sale: [],
      on_create: [],
    },
    onSubmit: formSubmitted,
  });

  useEffect(() => {
    if (!id) return;

    getConversionRuleQuery({
      id: id,
    })
      .unwrap()
      .then((data) => {
        setSale(data.on_sale);
        setCreate(data.on_create);
        form.setValues({ ...data });
      });
  }, [id]);

  useEffect(() => {
    if (form.values.source_type === 'lead') {
      getFormsQuery()
        .unwrap()
        .then((data) => {
          setSourceOptions(
            data.map((d) => ({
              value: d.id,
              label: d.name,
            }))
          );
        });
    } else if (form.values.source_type === 'postback') {
      getPostbacksQuery()
        .unwrap()
        .then((data) => {
          setSourceOptions(data.data.map((d) => ({ value: d.id, label: d.name })));
        });
    } else if (form.values.source_type === 'event') {
      form.setFieldValue('on_sale', []);
      const forms = getEventsQuery()
        .unwrap()
        .then((data) => {
          setSourceOptions(
            data.reduce((prev, d) => {
              prev[d.id] = { name: d.type, value: d.id };
              return prev;
            }, {})
          );
        });
    } else {
      setSourceOptions([]);
    }
  }, [form.values.source_type]);

  if (isLoading)
    return (
      <div className='w-full flex align-items justify-center'>
        <Spin indicator={<LoadingOutlined spin />} size='large' />
      </div>
    );

  const isSourceLoading = isFormsLoading || isPostbacksLoading || isEventsLoading;

  console.log(isSourceLoading);

  return (
    <Form className='flex flex-col' layout='vertical'>
      <Form.Item label='Rule Name'>
        <Input
          disabled={loading}
          size='large'
          placeholder='Enter Name'
          name='name'
          value={form.values.name}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
        />
      </Form.Item>

      <div className='sm:flex grid items-center w-full sm:gap-4'>
        <Form.Item label='Source Type' className='w-full'>
          <Select
            getPopupContainer={(triggerNode) => triggerNode.parentNode}
            disabled={loading}
            size='large'
            name='source_type'
            placeholder='Select Source Type'
            value={form.values.source_type}
            onChange={(val) => {
              form.setFieldValue('source_type', val);
              form.setFieldValue('source_ids', []); // clear source_ids when source_type changes
            }}
            options={[
              { value: 'lead', label: 'Crm Form' },
              { value: 'postback', label: 'Postback' },
            ]}
          />
        </Form.Item>
        <Form.Item label='Select Source' className='w-full'>
          <Select
            mode='multiple'
            maxTagCount='responsive'
            getPopupContainer={(triggerNode) => triggerNode.parentNode}
            disabled={loading}
            size='large'
            name='source_ids'
            placeholder='Select Source'
            value={form.values.source_ids}
            onChange={(val) => {
              form.setFieldValue('source_ids', val);
            }}
            options={sourceOptions}
          />
        </Form.Item>
      </div>

      <Form.Item label='Conversions On Sale'>
        <ConversionsSelector
          getPopupContainer={(triggerNode) => triggerNode.parentNode}
          size='large'
          className='w-full'
          placeholder='Conversions On Sale'
          name='on_sale'
          disabled={loading}
          value={form.values.on_sale}
          defaultValue={sale}
          multiple
          onChange={(val) => form.setFieldValue('on_sale', val)}
        />
      </Form.Item>
      <Form.Item label='Conversions On Create'>
        <ConversionsSelector
          getPopupContainer={(triggerNode) => triggerNode.parentNode}
          size='large'
          className='w-full'
          placeholder='Conversions On Create'
          name='on_create'
          disabled={loading}
          value={form.values.on_create}
          defaultValue={create}
          multiple
          onChange={(val) => form.setFieldValue('on_create', val)}
        />
      </Form.Item>

      <div className='py-4 flex justify-between'>
        <Button size='large' type='primary' loading={loading} onClick={form.handleSubmit}>
          {editing ? 'Update' : 'Create'}
        </Button>
      </div>
    </Form>
  );
};

ConversionRulesForm.propTypes = {
  editing: PropTypes.bool,
  loading: PropTypes.bool,
  id: PropTypes.number,
  onSubmit: PropTypes.func,
};
export default ConversionRulesForm;
