const P = {
  APP: '/',
  AUTH: {
    INDEX: '/auth/',
    LOGIN: '/auth/login/',
    REGISTER: '/auth/register/',
    PLAN: '/auth/choose-plan/',
    RESET_PASSWORD: '/auth/reset-password/',
    UPDATE_PASSWORD: '/auth/ext/reset-password/:hash',
    ACCOUNT: '/auth/account',
    CONFIRM: '/auth/confirm',
  },
  SETUP: {
    INDEX: '/setup/',
    QUESTIONS: '/setup/questions',
    CONFIRM_EMAIL: '/setup/:hash',
  },
  DASHBOARD: {
    INDEX: '/dashboard',
    PERMISSION_ERROR: '/no-permission',
  },
  PROFILE: {
    INDEX: '/profile',
    INVOICES: '/profile/settings/invoices',
    SETTINGS: {
      USER: '/profile/settings/user',
      SECURITY: '/profile/settings/security',
      WORKSPACES: '/profile/settings/workspaces',
      NETWORKS: '/profile/settings/networks',
      INTEGRATIONS: '/profile/settings/integrations',
      PREFERENCES: '/profile/settings/preferences',
      WORKSPACE: {
        USERS: '/profile/settings/workspace/users',
        INTEGRATIONS: '/profile/settings/workspace/integrations',
        PREFERENCES: '/profile/settings/workspace/edit',
        BILLING: '/profile/settings/workspace/billing',
        INVOICE: '/profile/settings/workspace/invoice',
      },
    },
  },
  BILLING: {
    INDEX: '/billing/',
    CHOOSE_PLAN: '/billing/choose-plan',
    APPSUMO: '/billing/redeem-appsumo-code',
  },
  AD: {
    INDEX: '/ad',
    CHOOSE_METHOD: '/ad/choose-method',
    CREATE: '/ad/create',
  },
  APPS: {
    INDEX: '/apps',
    CONNECT_APP: '/apps/:app',
  },
  AUDIENCES: {
    INDEX: '/audiences',
    CREATE: '/audiences/create',
  },
  CONVERSIONS: {
    INDEX: '/conversions',
  },
  CONVERSION_RULES: {
    INDEX: '/conversion-rules',
  },
  POSTBACKS: {
    INDEX: '/postbacks',
    OUTGOING: {
      INDEX: '/postbacks/outgoing',
    },
  },
  GUEST: {
    INDEX: '/guest',
    CONFIRM_INVITE: '/guest/confirm-invitation',
    CONFIRM_CRM_INVITATION: '/guest/crm/confirm-invitation',
  },
  REPORTING: {
    INDEX: '/reporting',
    CREATIVE: '/reporting/creative',
    INNER: {
      DASHBOARD: '/reporting/:id/dashboard',
      GENDER: '/reporting/:id/gender',
      DEVICES: '/reporting/:id/devices',
      AGE: '/reporting/:id/age',
      COMBINATIONS: '/reporting/:id/combinations',
      KEYWORDS: '/reporting/:id/keywords',
      SEARCH_TERMS: '/reporting/:id/search-terms',
      LOCATIONS: '/reporting/:id/locations',
      ADS: '/reporting/:id/ads',
    },
  },
  CRM: {
    INDEX: '/crm',
    DATANINJA: {
      FIELDS: '/crm/dataninja/:id',
      STATUSES: '/crm/dataninja/:id/statuses',
      ACCESS: '/crm/dataninja/:id/access',
      CONFIGURATION: '/crm/dataninja/:id/configuration',
      INTEGRATION: '/crm/dataninja/:id/integration',
      SUCCESS: '/crm/dataninja/:id/success',
    },
    WOOCOMMERCE: '/crm/woocommerce',
    DATA_LIST: '/crm/:id/list',
    CONTACTS: '/crm/:id/contacts',
    VIEW: '/crm/:id/view',
  },
  BUILDER: {
    INDEX: '/website-builder',
    CREATE: '/website-builder/create',
  },
  NOTIFICATIONS: {
    INDEX: '/notifications',
  },
  SALES: {
    INDEX: '/sales',
  },
  DOMAIN: {
    INDEX: '/domain',
    EDIT: (id) => `/domain/${id}/edit`,
    CREATE: '/domain/create',
    STATUS: '/domain/:status',
  },
  WIZARD: {
    INDEX: '/wizard',
  },
  RULES: {
    INDEX: '/rules',
  },
  URL: {
    INDEX: '/url',
    INNER: {
      DASHBOARD: '/url/:id/dashboard',
    },
  },
  REPORT: {
    INDEX: '/report',
    INSIGHTS: '/report/insights',
    MAIN: '/report/insights',
    USER_JOURNEY: '/report/insights/user',
  },
  ACCOUNTS: {
    INDEX: '/accounts',
  },
};

export default P;
