import ninjaApi from 'store/redux/apis/ninja.api';

/**
 * Returns sales
 */
export const salesApi = ninjaApi.injectEndpoints({
  overrideExisting: true,
  tagTypes: ['Sales', 'DefaultWorkspace'],
  endpoints: (build) => ({
    /* Fetch all sales */
    leadSalesList: build.query({
      query: (body) => ({
        url: '/sales/load-leads',
        method: 'POST',
        body,
      }),
      providesTags: ['Sales'],
    }),
    postbackSalesList: build.query({
      query: (body) => ({
        url: '/sales/load-postbacks',
        method: 'POST',
        body,
      }),
      providesTags: ['Sales'],
    }),
    conversionSend: build.mutation({
      query: (body) => ({
        url: '/network/conversions/send-offline-conversion',
        method: 'POST',
        body,
      }),
      providesTags: ['Sales'],
    }),
    sendEventConversion: build.mutation({
      query: (body) => ({
        url: '/network/conversions/send-event-conversion',
        method: 'POST',
        body,
      }),
      providesTags: ['Sales'],
    }),
  }),
});

export const {
  useSalesListQuery,
  useGetCollectEventTypesQuery,
  useSendEventConversionMutation,
  useConversionSendMutation,
} = salesApi;
