import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  dashboard: {
    id: null,
    name: 'dashboard',
    config: null,
  },
  old_config: {},
};

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: initialState,
  reducers: {
    setDashboardAction: (state, action) => {
      if (typeof action.payload !== 'object') return;
      state.dashboard = { ...state.dashboard, ...action.payload };
    },
    setOldConfigAction: (state, action) => {
      if (typeof action.payload !== 'object') return;
      state.old_config = action.payload; // Fixed typo from `old_layout` to `old_config`
    },
  },
});




export const { setDashboardAction, setOldConfigAction } = dashboardSlice.actions;

export default dashboardSlice.reducer;
