import { CircularProgress } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import ReactDataGrid from 'react-data-grid';
import { HTML5Backend } from 'react-dnd-html5-backend';
import './ninjatable.css';
import './templates.css';

const NinjaTableGrid = (props) => {
  const {
    // Pre defined styles, saved in styles variable
    template = 'outerreporting',
    loading,
    dynamicHeight,
    maxHeight,
    showFooter,
    rowHeight,
    sortColumns,
    onSortColumnsChange,
    ...rest
  } = props;

  /* Theme manager, get predefined from template */
  const theme = styles[template];

  /**
   * Returns row key
   */
  const rowKeyGetter = (row) => {
    return row.id;
  };

  // If dynamicHeight is passed, then table height is calculated based on records count
  if (dynamicHeight) {
    theme.styles.minHeight = theme.rowHeight * props.rows.length + theme.headerRowHeight + 8;
    if (props.summaryRows.length) {
      theme.styles.minHeight += theme.headerRowHeight;
    }
  }

  if (maxHeight && theme.styles.minHeight > maxHeight) {
    theme.styles.minHeight = null;
    theme.styles.maxHeight = maxHeight;
  }

  const DraggableComponent = props.wrapper;

  return (
    <div
      className={clsx('nnj-grid flex flex-col', template, {
        loading: loading,
        stripped: 'stripped',
      })}
      style={{ ...theme.styles }}
    >
      {/* Table loading indicator, Currently handled by only css */}
      <LoadingIndicator />
      <DraggableComponent backend={HTML5Backend}>
        <ReactDataGrid
          className={props.rows.length <= 0 ? 'flex-1 nnj-grid noResult' : 'flex-1 nnj-grid '}
          headerRowHeight={theme.headerRowHeight}
          rowKeyGetter={rowKeyGetter}
          {...rest}
          cellNavigationMode='NONE'
          rowHeight={rowHeight || theme.rowHeight}
          bottomSummaryRows={props.summaryRows}
          sortColumns={sortColumns}
          onSortColumnsChange={onSortColumnsChange}
          cellRenderer
        />
      </DraggableComponent>
    </div>
  );
};

const LoadingIndicator = () => {
  return (
    <div className='loading-div flex-center'>
      <CircularProgress />
    </div>
  );
};

const styles = {
  outerreporting: {
    backgroundColor: 'white',
    styles: {
      '--nnj-header-bgcolor': 'white',
      '--nnj-row-bgcolor': 'white',
      '--nnj-header-cell-height': '50px',
      '--nnj-header-font-size': '11px',
      '--nnj-header-color': 'black',
      height: 'auto !important',
    },
    headerRowHeight: 50,
    rowHeight: 40,
  },
  creativeReporting: {
    backgroundColor: 'white',
    styles: {
      '--nnj-header-bgcolor': 'white',
      '--nnj-row-bgcolor': 'white',
      '--nnj-header-cell-height': '50px',
      '--nnj-header-font-size': '11px',
      '--nnj-header-color': 'black',
      height: 'auto !important',
    },
    headerRowHeight: 50,
    rowHeight: 50,
  },
  crm: {
    backgroundColor: 'white',
    styles: {
      '--nnj-header-bgcolor': 'white',
      '--nnj-row-bgcolor': 'white',
      '--nnj-header-cell-height': '50px',
      '--border-right': '1px solid #F2F2F2',
      '--nnj-header-font-size': '11px',
      '--nnj-row-border-bottom': '1px solid #E5E5E5',
    },
    headerRowHeight: 50,
    rowHeight: 54,
  },
  conversion: {
    backgroundColor: 'white',
    styles: {
      '--nnj-header-bgcolor': 'white',
      '--nnj-row-bgcolor': 'white',
      '--nnj-header-cell-height': '50px',
      '--nnj-row-border-bottom': '1px solid #E5E5E5',
      '--nnj-header-font-size': '11px',
      '--nnj-header-color': 'black',
      height: 'auto !important',
    },
    headerRowHeight: 50,
    rowHeight: 50,
  },
  urls: {
    headerRowHeight: 50,
    rowHeight: 40,
  },
};

export default NinjaTableGrid;
